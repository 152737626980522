export default {
    data() {
        return {
            es: {
                //Cashier + CashierOptions
                cashier_cashierTitle: "Cajera",
                cashier_managerMenuTitle: "Menú del administrador",
                cashier_promotionsTitle: "Promociones",
                cashier_cashierOptionsTitle: "Opciones de cajero",
                cashier_managerOptionsTitle: "Opciones del administrador",
                sign_In: "Conectar",
                sign_Out: "Desconectar",
                cashierAmount: "Cajera Cantidad",
                lblBlocked: "Bloqueado",
                btnBlockCashier: "Bloquear Cajera",
                btnUnblockCashier: "Desbloquear Cajera",
                btnCashierCheck: "Cuadre Caja",
                btnAccInfo: "Info. de la Cuenta",
                btnCloseAcc: "Cerrar Cuenta",
                btnCloseAccs: "Cerrar Cuentas",
                btnRegisterFill: "Registrarse Rellenar",
                btnRegisterDrop: "Registrarse Baja",
                btnTerminalStatus: "Estado Terminales",
                btnReports: "Informes",
                btnPaidAccounts: "Tickets Pagados",
                btnInformesGistra: "Informes GISTRA",
                btnNavision: "Navision",
                btnAddCredits: "Agregar Créditos",
                btnNewPin: "Crear una Cuenta",
                drawerAmount: "INSERTE LA CANTIDAD INICIAL DE CAJA",
                drawerFinalAmount: "INSERTAR CANTIDAD FINAL DE CAJA",
                drawerFillCashier: "INSERTAR CANTIDAD DE CAJA DE LLENADO",
                drawerDropCashier: "INSERTAR CANTIDAD DE BAJA DE CAJA",
                drawnerBtnNewPin: "INSERTAR CREAR UNA CUENTA CAJA",
                drawnerCashierCheck: "INSERTAR CREAR UNA CUADRE CAJA",
                btnEdit: "Editar",
                btnDelete: "Eliminar",
                btnView: "Ver",
                btnClose: "Cerrar",
                btnOk: "Ok",
                btnTest: "Prueba",
                btnAdd: "Add",
                btnReturn: "Regresa",
                btnBack: "Regresa",
                btnCancel: "Cancelar",
                btnLogin: "Iniciar sesión",
                btnYes: "Sí",
                btnNo: "No",
                btnExit: "Salida",
                btnRefresh: "Actualizar",
                btnReset: "Restablecimiento",
                btnWait: "Espere...",
                btnSave: "Salvar",
                btnConfirm: "Confirmar",
                btnDiscard: "Descartar",
                btnApply: "Aplicar",
                btnReprintTicket: "Reimprimir ticket",
                lblPassword: "Contraseña",
                lblConfirmPassword: "Confirmar contraseña",
                lblValue: "Valor",
                lblNewValue: "Nuevo valor",
                lblReserveValue: "Restablecer Reserva",
                waiting: "Esperando",
                checkingLicense: "Verificando la licencia",
                cmdStartCashier: "Iniciar cajera",
                cmdFinshCashier: "Cajera de fin",
                cmdCashierBlock: "Bloquear Cajera",
                cmdCashierUnblock: "Desbloquear Cajera",
                cmdAccountInfo: "Cajera Info. de la Cuenta",
                cmdCloseAccounts: "Cajera Cerrar Cuentas",
                cmdCloseAccountsOK: "Boleto de cierre",
                cmdCloseAccountsCancel: "Cancelar boleto",
                cmdCashierRegisterFill: "Cajera Registrarse Rellenar",
                cmdCashierRegisterDrop: "Cajera Registrarse Baja",
                cmdCashierAddNewPin: "Cajera Crear una Cuenta",
                messageSignOutCashier: "Estás seguro de que quieres cerrar Cajera?",
                messageBlockCashier: "Estás seguro de que quieres bloquear Cajera?",
                messageUnblockCashier: "Estás seguro de que quieres desbloquear Cajera?",
                messageErroBlockUnblockCashier: "Solo el usuario Cajera puede bloquear o desbloquear Cajera",
                ticketTitle: "Info. De La Cuenta Ingrese El Número De La Cuenta",
                authenticationTitle: "Autenticación",
                columnTicket: "Boleto",
                lblSearchAccount: "Buscar por cuenta",
                reprintAccount_Title: "Reimprimir cuenta",
                btnReprint: "Reimpresión",
                messageSessionClose: "La sesión no está abierta...",
                lblWarning: "Advertencia",
                cashier_Data: "Datos del cajero",
                columnStartCash: "Iniciar efectivo",
                columnTotalFill: "Relleno total",
                columnTotalDrop: "Caída total",
                columnTotalAccountIn: "Total Account In",
                columnTotalAccountOn: "Total Account Out",  
                columnTotalCompAccount: "Total Complementary Account",
                columnFinalCash: "Efectivo final",
                columnData: "Datos",
                messageSignOutPart1: "Solo usuario[",
                messageSignOutPart2: "] puede hacer el cierre de sesión",

                //ManagerOptions
                btnUnblockMachines: "Desbloquear Máquinas",
                btnBlockMachines: "Bloquear Máquinas",
                btnAutoBlockMachines: "Máquinas automáticas",
                messageBlockMachines: "Estás seguro de que quieres bloquear Máquinas?",
                messageUnblockMachines: "Estás seguro de que quieres desbloquear Máquinas?",
                btnCloseSession: "Cerrar Sesión",
                btnOpenSession: "Abrir Sesión",
                messageOpenSession: "¿Realmente quieres abrir la sesión?",
                messageCloseSession: "¿Realmente quieres cerrar la sesión?",
                messageCloseSessionAlert: "Hage clic en el botón\n"+
                "'Imprimir Informe' para\n"+
                "imprimir informe de sesión",
                messageOpenSessionAlert: "Si el informe de la última sesión no fue\n"+
                "impreso, al hacer esta acción\n"+
                "hará que estos datos se pierdan y\n"+
                "no podré reimprimir más tarde\n\n\n\n"+
                "¿Quieres continuar sin imprimir?",
                btnCopyReport: "Copiar Informes",
                btnPartialReport: "Partial Report",
                btnGenerateReport: "Generar Informes",
                btnPrintReport: "Imprimir Informe",
                messageCashierOpen: "La sesión de cajero está abierta, ¡cierra la sesión!",

                //Header
                messageHasEmailToSend: "Hay archivos que deben ser enviados por el remitente del correo electrónico",
                massageRestartServer: "¿Realmente quieres reiniciar Megapot Server?",
                cmdRestartServer: "Reiniciar servidor",
                messageCloseServer: "¿Realmente quieres cerrar Megapot Server?",
                cmdServerStop: "Cerrar solicitud",
                messageSignOut: "¿Realmente quieres Desconectar?",
                cmdBlockCommunication: "Bloquear la comunicación",
                messageBlockCommunication: "¿Realmente quieres bloquear la comunicación?",
                cmdUnblockCommunication: "Desbloquear la comunicación",
                messageUnblockCommunication: "¿Realmente quieres desbloquear la comunicación?",
                messageAllMachinesConnect: "Todas las máquinas se conectarán desde el servidor.",
                messageAllMachinesDisconnect: "Todas las máquinas se desconectarán del servidor.",
                changePassword: "Cambiar contraseña",
                btnAddCash: "Añadir crédito",
                btnLock: "Menú bloquear",
                btnResetClient: "Restablecer Cliente",
                btnCloseClient: "Cerrar Cliente",
                language: "Idioma",
                messageTurnOffServer: "Esta acción apagará el servidor.",
                languageName: "Español",

                //Settings
                btnLogoff: "Cerrar sesión",
                btnForcedLogOff: "Cierre de sesión forzado",
                btnRestart: "Reanudar",
                btnShutdown: "Apagado",
                messageProcessLogOff: "¿Realmente quieres cerrar sesión?",
                messageProcessForcedLogOff: "¿Realmente quieres forzar el cierre de sesión?",
                messageProcessRestart: "¿Realmente quieres reiniciar?",
                messageProcessShutdown: "¿Realmente quieres apagar?",

                //Langauge
                messageSetLanguage: "Esperando para cambiar de idioma ...",

                //Login
                messageErrorLogin: "Nombre de usuario o contraseña incorrectos",
                messageErrorPassword: "¡Contraseña incorrecta!",
                messageErrorByPermission: "El usuario no tiene permiso",

                //Reset Value
                messageErrorValue: "El valor debe ser de",
                messageErrorReserveValue: "El valor de reserva debe ser de",
                messageErrorGrandValue: "El valor [Grand] debe ser de",
                messageErrorMinorValue: "El valor [Minor] debe ser de",
                messageErrorMajorValue: "El valor [Major] debe ser de",
                to: "a",
                cmdResetBonus: "Esperando para restablecer",
                columnChange: "Cambio",
                lblNotChanged: "No cambiado",
                confirmReset: "Establecer nuevos valores",
                messageConfirm: "¿Estás seguro?",
                lblValueWillBe: "El valor será", 
                lblResetType: "Tipo de restablecimiento",
                lblPayNoConnection: "Pago sin comunicación" ,
                lblAdjustmentSystem: "Ajuste debido a un fallo del sistema",
                lblPaidValue: "Valor pagado",
                messageWarningResetValuePartOne: "Al seleccionar esta opción, se registrará un nuevo pago de ",
                messageWarningResetValuePartTwo: " Esto hará que el último pago sea sustituido por este valor.",               

                //Terminals
                terminalsTitle: "Terminales",
                terminalInfoTitle: "Información de Terminales",
                lblCounter_Title: "Contadores",
                lblGameData: "Datos del juego",
                lblTotalTerminals: "Total terminales",
                lblLoggedTerminals: "Terminales registrados",
                lblFinishedLogin: "Inicio de sesión finalizado",
                lblGamesAvailable: "Juegos disponibles",
                lblInLogin: "En proceso de inicio de sesión",

                //columnTerminals: 
                columnTerminalNumber: "Número de Terminal",
                columnRealMachNum: "Número de Máquina Real",
                columnTerminalIP: "Terminal IP",
                columnLocalization: "Localización",
                columnGameName: "Nombre del juego",
                columnGameGroup: "Game Group",
                columnGameNumber: "Game Number(Last Play)",
                columnProgressiveGroup: "Groupo Progressive",
                columnMoney: "Dinero",
                columnSoftwareVersion: "Versión del software",
                columnCredit: "Crédito",
                columnTotalCoinIn: "Total de monedas en",
                columnTotalCoinOut: "Total de salida de monedas",
                columnTotalGamesPlayed: "Total de partidos jugados",
                columnTotalCashIn: "Total Cash In",
                columnTotalCashOut: "Total de efectivo",
                columnTotalHandPays: "Total de pagos manuales",

                //CopyReport: 
                availableFiles: "Archivos disponibles",
                addFiles: "Archivos añadidos que se administraron",
                messageNoFiles: "No se han encontrado archivos",
                messageLargeFiles1: "Tamaño de los archivos seleccionados: ",
                messageLargeFiles2: "Los archivos de más de 500 MB pueden tardar un poco en copiarse",
                messageLargeFiles3: "Nota: Los archivos se comprimirán antes de copiar",
                btnViewErrors: "Ver errores",
                btnResend: "Reenviar",
                btnCopyFile: "Copiar Archivo",

                //Email
                emails: "Correos electrónicos",
                email: "Correo electrónico",
                btnAccountConfig: "Configuración de la cuenta",
                btnSendFilesNow: "Enviar archivos ahora",
                btnSizeConfig: "Configuración de tamaño de archivo",
                messageSetMaxFileSize: "Guardar tamaño máximo de archivo",
                lblSearchEmail: "Buscar por correo electrónico...",
                account: "Cuenta",
                SMTPServer: "Servidor SMTP",
                SMTPAuthenticate: "Autenticación SMTP",
                SMTPPort: "Puerto SMTP",
                useStartTLS: "Usar TLS de inicio (SSL)",
                SMTPTimeout: "INTERRUPCIÓN SMTP",
                btnTestSend: "Envío de prueba",
                tabOthers: "Otros",
                tabRetry: "Reintentar",
                tabNotification: "Notificación",
                EmailNotificationId: "Email NotificationId",
                EmailNotificationCode: "Code",
                EmailNotification: "E-mail",
                automaticEmailSender: "Remitente automático de correo electrónico",
                mobileInternet: "Internet móvil",
                serialPortName: "Nombre de puerto serie",
                commDialUpName: "Nombre de acceso telefónico de comunicaciones",
                firstRetry: "Primer intervalo de reintento",
                secondRetry: "Segundo intervalo de reintento",
                thirdRetry: "Tercer intervalo de reintento",
                subsequentRetry: "Intervalo de reintento posterior",
                delayNotification: "Notificación de retraso",
                expirationTimeout: "Tiempo de espera de caducidad",
                reportListTitle: "Lista de informes",
                emailConfigTitle: "Configuración de correo electrónico",
                msgErrorEmail: "Correo electrónico no configurado",
                messageSendManualEmail: "Esperando para enviar correos electrónicos...",
                messageTestEmail: "Espere hasta que pruebe el servidor de correo electrónico...",

                //Coluns name
                columnName: "Nombre",
                columnEmail: "Correo electrónico",
                columnActions: "Acciones",
                columnField: "Campo",
                columnValue: "Valor",
                columnPlatform: "Plataforma",

                //Time
                hours: "Horas",
                minutes: "Minutos",
                seconds: "Minutos",
                days: "Días",

                //Panels
                panelsTitle: "Paneles",
                panelTitle: "Panel",
                lblSearchPanels: "Buscar por Panel...",
                lblSearchFooter: "Buscar por pie de página......",
                columnText: "Texto",
                btnDisplayName: "Mostrar Nombre Painel",
                messageDisplayName1: "Esta acción mostrará el nombre del panel",
                messageDisplayName2: "¿Está seguro de que desea continuar?",
                messageDisplayNameCMD: "Enviando el nombre para mostrar en cada panel...",
                messageDisplayNameFalse1: "Actualmente se muestra un mensaje con el nombre del panel en <b>Todos los</b> paneles.",
                messageDisplayNameFalse2: "<b>Si desea detener esta pantalla, presione el botón 'Cancelar pantalla'",
                btnCancelDisplay: "Cancelar visualización",
                btnSetGroups: "Establecer grupos",
                btnSetFooterPlaylist: "Establecer lista de reproducción de pie de página",
                btnSetPlaylist: "Establecer lista de reproducción",
                processDeletePanelPart1: "¿Está seguro de que desea <b>eliminar</b> su panel? <b>(",
                processDeletePanelPart2: ")</b>? Esta acción no se puede deshacer.",
                messageDeletePanel: "Eliminando panel...",
                processDeletePanelTitle: "Eliminando panel",
                btnRestartPanel: "Panel Reiniciar",
                processRestartPanelPart1: "¿Está seguro de que desea <b>reiniciar</b> el panel <b>(",
                processRestartPanelPart2: ")</b>? Esta acción no se puede deshacer.",
                messageRestartPanel: "Panel de reexpresión...",
                processRestartPanelTitle: "Panel de reexpresión",
                btnShutdownPanel:"Panel de apagado",
                processShutdownPanelPart1: "¿Está seguro de que desea <b>apagar</b> el panel <b>(",
                processShutdownPanelPart2: ")</b>? Esta acción no se puede deshacer.",
                messageShutdownPanel: "Panel de apagado...",
                processShutdownPanelTitle: "Panel de apagado",
                btnResetFactoryPanel: "Restablecer panel de fábrica",
                processResetFactoryPanelPart1: "¿Está seguro de que desea <b>restablecer el panel de fábrica </b> <b>(",
                processResetFactoryPanelPart2: ")</b>? Esta acción no se puede deshacer.",
                messageResetFactoryPanel: "Panel de descanso...",
                processResetFactoryPanelTitle: "Panel de descanso",

                //Coluns name
                columnVersion: "Versión",
                columnPanel: "Tablero",
                columnCommVersion: "CommVersion",
                columnStatus: "Estado",
                lblConnected: "Conectado",
                lblDisconnected: "Desconectado",

                //Progressive
                progressive_Title: "Progresivo",
                multiProgressives_Title: "MultiProgresivos",
                multiProgressive_Title: "MultiProgresivo",
                progressive_TabName: "Datos Progresivos",
                machines_Title: "Máquinas",
                lblSearchIPorMachNum: "Buscar por IP o MachNum...",
                btnResetProgressive: "Restablecer Progresivo",
                btnShowProgressive: "Mostrar Progresivo",
                btnDeleteTerminal: "Eliminar terminal",
                btnPaymentTerminal: "Terminal de Pago",
                btnOpenMonitor: "Abrir Monitor",
                lblSearchProgressive: "Buscar por Progresivo",
                lblSearchGames: "Buscar por juegos",
                lblSearchStatus: "Buscar por estado",
                lblSearchTerminalInNone: "Terminales en NINGUNO",
                lblSearchAllProgressive: "Todos los Progresivos",
                lblSearchMultiProgressive: "Buscar por MultiProgresivo",
                lblSearchAllInThisProgressive: "Todo en este Progresivo",
                lblTypeProgressive: "Tipo Progresivo",
                btnMoveProgressive: "Mover Terminal a Progresivo",
                switchSelectAll: "Seleccionar todo",
                messageSetTerminalProgressive: "A la espera de moverse progresivo(s) ...",
                messagePaymentTerminal: "A la espera de terminal(es) de pago...",
                messageOpenTerminal: "Esperando para abrir Terminal(es) ...",
                messageDeleteTerminal: "Esperando a eliminar terminal(es) ...",
                messageNoTerminalsAvailable: "No hay terminales disponibles",
                messageBtnOkMoveTermnalToProgressive: "Seleccione un terminal para soltar el botón",
                messageBtnShowTerminalOptions: "Opciones de terminal",
                messageBtnShowClientOptions: "Opciones del cliente",
                massageConfigTerminalOptionPayment: "¿Quieres realizar el pago de esta máquina?",
                massageConfigTerminalOptionOpen: "¿Desea abrir la configuración de esta máquina?",
                massageConfigTerminalOptionDelete: "¿Desea eliminar esta máquina?",
                lblSelectOption: "Seleccione una opción",
                lblSelectOperator: "Seleccione un Operador",
                lblGroupMachines: "Máquinas de grupo",
                lblClosed: "Cerrado",
                lblOpen: "Abrir",
                fractioned_Title: "Fraccionada",
                lblTableEnginesDataProg: "Motores en este Progressive",
                columnTotalBet: "Apuesta Total",
                columnPercentage: "Porcentaje",
                btnBlockClient: "Bloquear cliente",
                btnUnblockClient: "Desbloquear cliente",
                msgBlockClientConfirmation: "¿Quieres bloquear a este cliente?",
                msgUnblockClientConfirmation: "¿Quieres desbloquear a este cliente?",
                lblWarnings: "Advertencias",
                msgWarnings: "Hay advertencias del servidor, haz clic aquí para verlas",

                //columnNameProg
                columnProgressiveType: "Tipo Progresivo",
                columnProgressiveConfig: "Configuración Progresiva",
                columnConfig: "Config",   
                columnCoinValue: "Valor de la moneda",
                columnPlayJackpotSoundOn: "Sonido del Jackpot en el Juego",
                columnContribution: "Contribución",
                columnLevel: "Nivel",
                columnIP: "IP",
                columnInitialValue: "Valor Inicial",
                columnMaxValue: "Valor Máximo",
                columnMinBetToPartipate: "Apuesta mínima para participar",
                columnMaxProportionalBet: "Apuesta proporcional máxima",
                columnEnableIncSound: "Reproducir Sonido de Incremento",
                columnIncrementSoundValue: "Valor de Sonido de Incremento",
                columnReservePerc: "Porcentaje de Reserva",
                columnReserveValue: "Valor de Reserva",
                columnReserveMax: "Valor Máximo de Reserva",
                columnMidasFortuneBonus: "Midas Fortune Bonus",

                //columnNameMultiProg
                columnMultiProgressiveSettings: "Configuración Multiprogresivo",
                columnMultiProgressiveType: "Tipo Multiprogresivo",
                columnMultiProgressiveConfig: "Configuración Multiprogresiva",
                columnTotalContribution: "Contribución Total",
                settings: "Configuración",

                //Users
                users_Title: "Usuarios",
                user_Title: "Usuario",
                user_tabName: "Datos de Usuarios",
                report_tabName: "Datos de Informe",
                btnAddUser: "Agregar Usuario",
                btnAddReportLevel: "Agregar Nivel de Informe",
                lblSearchByLogin: "Buscar por inicio de Sesión...",
                lblSearchByName: "Buscar por inicio de Nombre...",
                messageDeleteUser: "Esperar para Eliminar Usuario",
                processDeleteUserTitle: "Eliminar Cuenta",
                processDeleteUserPart1: "¿Está seguro de que desea <b>eliminar</b> su cuenta <b>(",
                processDeleteUserPart2: ")</b>? Esta acción no se puede deshacer.",

                messageDeleteReportLevel: "Esperando para eliminar Nivel de Informe",
                processDeleteReportTitle: "Eliminar el nivel de Informe",
                processDeleteReportPart1: "¿Está seguro de que desea <b>eliminar</b> su nivel de informe <b>(",
                processDeleteReportPart2: ")</b>? Esta acción no se puede deshacer.",

                //Column User
                columnLastUpdated: "Última actualización",
                columnFunction: "Función",
                columnLogged: "Anotado",
                columnNotLogged: "No registrado",

                //User Manager
                userManager_Title: "Administrador de usuarios",
                usersManagement: "Gestión de usuarios",
                cashier_Option: "Cajero",
                technician_Option: "Técnico",
                reportView_Option: "Vista de informe",
                manager_Option: "Director",
                root_Option: "Raíz",
                lblSelectALevel: "Seleccione un nivel",
                messageErrorInvalidName: "Nombre no válido. Solo se permiten letras y números.",
                messageUserExist1: "Iniciar sesión [",
                messageUserExist2: "] ya existe",
                messageErrorOptions: "Por favor, marque al menos una opción",
                messageErrorOperator: "Seleccione al menos un operador",
                processCreateUser: "Esperando para crear usuario",
                processEditUser: "Esperando para editar el usuario",
                lblMultipleFunctions: "Múltiples funciones",
                lblSessionMangerFunction: "Gerente de Sesión",
                lblSearchOrAddTag: "Buscar o agregar una etiqueta",
                lblAddThisTag: "Agregue esto como nueva etiqueta",
                lblSearchOrAddOperator: "Buscar o agregar un operador",
                lblAddThisOperator: "Agregar esto como nuevo operador",
                lblSelectALabel: "Pulse Intro para seleccionar",
                lblDeselectLabel: "Pulse Intro para eliminar",
                messageReLogin: "Haga clic en Aceptar para iniciar sesión con la nueva contraseña",
                messageChangePassword: "¡Por favor actualice su contraseña!",

                //Report Level Manager
                reportLevelManager_Title: "Configuraciones de acceso a informes",
                errorMessageInvalidReport: "Seleccione al menos un informe.",
                messageCreateReport: "A la espera de crear un informe...",
                messageEditReport: "A la espera de editar el informe...",

                //Configurations
                configurations_Title: "Configuraciones",
                deckDatabase_Title: "Base de datos",
                deckEstablishment_Title: "Información del Servidor",
                deckResetSettings_Title: "Opciones",
                btnLicense: "Licencia de Servidor",
                btnCopyLogs: "Copia Trozas",
                btnCopyCert: "Copia Certificado",
                btnLoadSettings: "Configuración de carga",
                btnBackup: "Copia de seguridad",
                btnRestore: "Restaurar",
                stepLoadFile: "Cargar archivo",
                stepCredentials: "Credenciales",
                lblTechnicianLogin: "Inicio de sesión del técnico",
                lblExecutionCode: "Código de ejecución",
                lblDatabasePassword: "Contraseña de la base de datos",
                errorMessagePasswordTooShort: "La contraseña es demasiado corta",
                errorMessagePasswordAtLeast: "La contraseña debe tener al menos 8 caracteres",
                stepInformation: "Información",
                stepFinish: "Finalizar",
                messageDownloadConfig: "Descargando la configuración, por favor espere...",
                messageUploadConfig: "Cargando configuración, por favor espere...",
                messageUploadDatabase: "Cargando la base de datos, por favor espere...",
                errorMessageInvalidExecutionCode: "Código de ejecución no válido",
                errorMessageInvalidLoginWithExecutionCode: "Código de ejecución, nombre de usuario o contraseña no válidos",
                errorMessageInvalidLogin: "Nombre de usuario o contraseña",
                messageInstallingConfiguration: "Instalación de configuraciones, espere ...",
                messageRequestConfig: "Solicitar configuraciones...",
                messageRequestFiles: "Solicitar archivos...",
                messageRequestPenDriveList: "Solicitar lista de PenDrive",
                messageDoBackupCMD: "Haciendo copia de seguridad de registros y base de datos...",
                btnResetServerConfiguration: "Restablecer la configuración del servidor",
                btnResetGlobalConfiguration: "Restablecer la configuración del Megapot",
                btnResetClientConfiguration: "Restablecer configuración Cliente",
                messageResetClient: "¿Realmente desea reiniciar la configuración del cliente?",
                btnResetBothConfiguration: "Restablecer la configuración del servidor y del Cliente",
                btnResetS2SConfiguration: "Restablecer la configuración de S2S",
                btnResetBigOsAddress: "Restablecer la dirección de BigOS",
                btnResetGlobalClient: "Restablecer dirección Megapot",
                btnManageSystem: "Administrar sistema",
                messageRunOsAdmin: "Espera, abriendo Os Admin...", 
                messageHasEmailSenderPart1: "Hay una configuración anterior de remitente de correo electrónico.",
                messageHasEmailSenderPart2: "¿Quieres conservarlo?",
                messageHasEmailSenderPart3: "(Obs.:La configuración del correo electrónico, si se mantiene, los informes se pueden enviar al Reportal).",
                messageDiscardEmailSender: "Espera, eliminando el remitente del correo electrónico ...",
                messageGenerateLicenseCMD: "Generando pedido...",
                messageGenerateLicenseAutomaticCMD: "Solicitud de licencia...",
                msgCheckAutoLicense: "Tienes la dirección de BigOS configurada y puedes intentar generar tu licencia automáticamente, ¿quieres continuar? Si eliges 'NO' podrás hacer el proceso manual",
                msgCancelLicenseReq: "Generar nueva solicitud de licencia",
                msgCancelLicenseQuest: "La generación de una nueva solicitud de licencia invalidará cualquier solicitud abierta anteriormente. ¿Quieres continuar?",
                msgGenNewLicense: "Esta acción generará una nueva solicitud de licencia, que no cancela la licencia actual, si tiene una que funcione. ¿Quieres continuar?",
                msgDeleteDatabase: "Esta acción eliminará la base de datos. ¿Quieres continuar?",
                msgDeletingDatabase: "Eliminación de dabatase...",

                //Session
                stepChooseReport: "Elija Informe",
                messageChooseReport: "Elija el tipo de informe",
                btnCurrent: "Actual",
                btnBySession: "Por sesión",
                stepReportType: "Tipo de informe",
                messageReportType: "Se generará un nuevo informe Seleccione el tipo de informe que desea generar.",
                btnFinal: "Final",
                btnPartial: "Parcial",
                stepGenerateCSV: "Generar CSV",
                messageGenerateCSV: "Generar CSV por sesión o Fecha",
                btnGenerate: "Generar",
                messageReportTypeFinal: "Esperando a Reportar Tipo Final...",
                messageReportTypePartial: "Esperando a Reportar Tipo Parcial...",
                messageInformationBySession: "Esperando a Información Por Sesión...",
                messageGenerateCSVRequest: "Esperando a Generar CSV...",
                btnManualCashierSignOut: "Cajero manual Cerrar sesión",
                lblSearchByTerminal: "Buscar por terminal...",
                session_Title: "Sesión",
                lblStartedAt: "Comenzó en",
                lblClosedAt: "Cerrado en",
                lblType: "Tipo",
                lblLastSessionClose: "Cierre de la última sesión",
                lblSessionControl: "Control de sesión",
                lblSessionControlManual: "Manual",
                lblSessionControlAutomatic: "Automático",
                lblSessionControlSemiAutomatic: "Semi automático",
                lblSearchCashier: "Buscar por cajera...",

                //Footer
                serverVersion: "Versión del servidor",
                sessionStatus: "Session Status",

                //Token
                token_Title: "Seña",
                token_messageInfo: "Informe la contraseña a continuación a la central para continuar",
                token_messageError1: "¡Contraseña incorrecta! tienes ",
                token_messageError2: " pruebas más",

                //Auth
                messageAuthReportManager: "Para acceder al area de informes, inicie sesión como usuario habilitado para el módulo.",
                messageErrorNotSet: "no está configurado para",
                messageLoggedIn: "ha iniciado sesión temporalmente",

                //ReportManager
                reportManager_Title: "Informes",

                //Bonus
                bonus_Title: "Bonus",
                bonusData_tabName: "Datos de bonificación",
                engineData_tabName: "Datos de Juegos",
                lblResetBonus: "Restablecer bono",
                lblTableEnginesData: "Juegos en este bono",

                //ColumnBonus
                columnMrChampionConfig: "Mr Champion Configuración",
                columnMinPlayersMystery: "Min Jugadores to Mystery",
                columnPigBoomConfig: "Pig Boom! Configuración",
                columnMinPlayersBonus: "Min Jugadores to Sobresueldo",
                columnPrizeValue1: "Valor del premio 1",
                columnPrizeValue2: "Valor del premio 2",
                columnPrizeValue3: "Valor del premio 3",
                columnPrizeMode: "Modo Premio",
                columnTimeToRestart: "Tiempo para reiniciar (minutos)",

                //IniFront
                iniFront_Title: "Configuración del cliente",
                lblPreferencialIP: "Preferencial IP",
                messageErrorIP: "Por favor introduce una dirección válida",
                messageCreatedIniFile: "Creado iniFile, Espere a reiniciar...",
                messageNoConnection: "Sin conexión con IP",
                iniFile_Title: "Archivo Ini",
                messageNeedsIPConfig: "FBM Server necesita estas configuraciones para ejecutarse, por favor configure para usar.",
                lblValidatinIP: "Validación de IP...",
                lblErrorDeleteFile: "Error en Eliminar archivo de cliente",
                messageSaveBigOsSettings: "Guardando la configuración de BigOS, espere ...",

                //IniServer
                iniServer_Title: "Configuraciones del servidor",
                lblCountryCode: "Código de país",
                messageErrorCountryCode: "Seleccione un país",
                lblDatabaseIP: "IP de base de datos",
                fbmServer_Option: "Ejecutar Megapot Server al inicio de Windows",
                spMaster_Option: "Ejecutar SP-Master al inicio de Windows",
                fbmServerWillRestart: "Megapot Server reiniciará...",
                lblFillAlesis: "Por favor, rellene la configuración de Alesis",
                btnCheckTime: "Intervalo de comprobación (minutos)",
                btnBigosAddress: "BigOs Dirección",
                checkAutomatic: "Comprobar automáticamente si hay nuevas configuraciones",
                checkAutomaticTerminal: "Enviar estado del terminal",
                messageConfiguratorSettingsPart1: "Tenga en cuenta que, dependiendo del entorno utilizado, la URL puede cambiar.",
                messageConfiguratorSettingsPart2: "Si está utilizando SystemConfigurator, debe terminar la dirección con SystemConfigurator, ejemplo https://example.com/SystemConfigurator.",
                messageConfiguratorSettingsPart3: "Si está utilizando BigOS sin https, debe informar al puerto 9000, ejemplo http://example.com:9000, si está utilizando https termine la dirección con backend, ejemplo https://example.com/backend.",
                messageConfiguratorSettingsPart4: "La primera parte de la dirección http(s):// es necesaria en todos los escenarios",

                //GlobalForm
                global_Title: "Configuración Megapot",
                isGlobal_Title: "Configuración Megapot",
                isGlobalProgressive: "Utilice Megapot",
                globalProgressiveMode: "Modo Megapot",
                clientOnly_Option: "Solo cliente",
                serverOnly_Option: "Sólo servidor",
                clientAndServer_Option: "Cliente y Servidor",
                lblGlobalProgressiveServerIP: "IP de servidor progresiva Megapot",
                globalPreferencialIP_Option: "Conexión IP Preferencial Megapot",
                anotherLocalIP_Option: "Usar otra IP local para conectarse",

                //GistraForm
                gistra_Title: "Configuración de Gistra",
                lblServerIP: "IP del servidor",
                messageErrorSelectLogin: "Seleccione Iniciar sesión",
                lblPort: "Puerto",
                messageErrorPort: "Seleccione un puerto",

                //AlesisForm
                alesis_Title: "Configuración de Alesis",
                lblSeller: "ID de Proveedor",
                messageErrorlblSeller: "El ID de Proveedor está vacío",
                lblSqlUser: "Usuario SQL",
                messageErrorlblSqlUser: "El usuario SQL está vacío",
                lblDataBaseName: "Nombre de la base de datos",
                messageErrorlblDataBaseName: "El nombre de la base de datos de Alesis está vacío",
                lblSqlPassword: "Contraseña SQL",
                lblConfirmSqlPassword: "Confirmar contraseña sql",
                messageErrorProvider: "Seleccione un proveedor",

                //BigOs
                messageErrorCheckTime: "El valor no puede ser 0 o menor",

                //App
                hideMenuMessage: "Esperando para ocultar el menú...",
                hideShowMessage: "A la espera de mostrar el menú...",

                //Promotions 
                menuPromotions_Title: "Promociones en el menú",
                validateCoupon_Title: "Validar cupón",
                generateCoupon_Title: "Generar cupón",
                couponManagement_Title: "Gestión de cupones",
                messageGetPromotionsInfo: "Esperar hasta que obtengas información sobre la promoción y los premios",
                lblPromoNoAvailable: "No hay promociones disponibles",
                messageValidateCoupon: "Validación del código de barras",
                messageErrorCode: "Validación de ErrorCode",
                confirmation_Title: "Confirmación",
                messageUpdateCoupon: "Confirmación y actualización del cupón",
                messageGenerateCoupon: "Generación de cupones",
                messageFindByVlt: "Confirmación del VLT",
                btnRefreshMachines: "Actualizar Maquinas",
                messageRefreshMachines: "Buscando máquinas...",

                //ValidateCoupon
                lblCurrentPromotion: "Promoción actual",
                lblStarts: "Comienza",
                columnDrawn: "Dibujado",
                columnOrder: "Orden",
                columnAward: "Adjudicar",
                columnCoupon: "Cupón",
                columnDate: "Dia",
                btnValidate: "Validar",

                //GenerateCoupon
                lblNoCodeAvailable: "No hay código disponible",
                lblContactCentral: "Contacta con Central",
                btnSearchMachine: "Busca por Máquina...",
                lblMachineOutOfService: "Máquina fuera de servicio",
                lblCriterionBet: "Apuesta + Criterio",
                lblAvailableMachine: "Máquina(s) disponible(s)",
                lblCoupons: "Cupones",

                //CouponManagement
                lblEnterPromotions: "Ingrese el Código de Promoción",
                lblPromotionCode: "Código de promoción",
                lblUserInformation: "Información del usuario",
                lblAddress: "Dirección",
                lblPhone: "Teléfono",
                lblIdentificación: "Identificación",
                columnCouponCode: "Código de cupón",
                columnMachine: "Máquina",
                columnPromotion: "Promoción",
                columnaCriterio: "Criterio",
                columnaImpreso: "Impreso",
                btnPrintCoupons: "Imprimir Cupones",
                btnReprintCoupons: "Reimprimir cupones",
                btnPrintTest: "Prueba de impresión",
                messagePrintTestCoupon: "Imprimiendo cupón de prueba...",

                //PlayersAccount
                lblStatusFilter: "Filtro de estado",
                lblCreationDateFilter: "Filtro de fecha de creación",
                free_Option: "Gratis",
                used_Option: "Usado",
                other_Option: "Otro",
                paying_Option: "Pagar",
                expired_Option: "Caducado",
                created_Option: "Crear",
                canceled_Option: "Cancelado",
                lblInitialDate: "Data Inicial",
                lblFinalDate: "Data Final",
                lblChooseADate: "Elige una fecha",
                on_Option: "En",
                lblErrorSelectDates: "Se deben seleccionar ambas fechas.",
                columnBalance: "Equilibrar",
                columnExpires: "Caduca a las",
                playersAccount_title: "Cuenta de jugadores",

                lblNoDataFound: "No se han encontrado datos...",
                lblSearchByPin: "Buscar por Pin...",
                lblLimit: "Límite",

                //Keyboard
                btnBackspace: "Retroceso",
                btnClear: "Limpar",
                lblOpenKeyboard: "Abrir teclado",

                //Report Level
                lblFinancial: "Financiero",
                reportSessionBalanceTot: "Informe total del saldo de la sesión",
                reportSessionReport: "Informe de la sesión",
                reportPrize: "Premio",
                reportPayOutSummary: "Informe de resumen de pago",
                reportProgressiveGroupReport: "Informe del Grupo del Progresivo",
                lblJackpotGlobal: "Megapot",
                reportStatusReport: "Informe de estado",
                reportPrizeReport: "Informe del Premio",
                lblGame: "Juego",
                reportGameListReport: "Informe de la lista de juegos",
                reportGameGroupReport: "Informe del grupo de juegos",

                lblVouchers: "Vales",
                reportIssuance: "Emisión",
                reportRedemption: "Redención",
                reportUnclaimed: "Mostrenco",
                reportCashOut: "Retiro de dinero",
                lblTerminal: "Terminal",
                lblLiability: "Responsabilidad",
                reportGameTerminalReport: "Informe de la terminal de juego",
                reportTerminalEventReport: "Informe de eventos de terminal",
                reportGameTerminalCountsReport: "Informe de recuentos de terminales de juego",
                reportReconcilationReport: "Informe de reconciliación",
                reportReportAccountingFinal: "Informe Contable Final",
                reportRamClearHistReport: "Informe Ram Clear Hist",
                reportPlayHistReport: "Informe Play Hist",
                reportBillIn: "Facturar en",
                lblGenReports: "Informes gen",
                lblServerReports: "Informes del servidor",
                reportTerminalSalesReport: "Informe de ventas de terminales",
                reportReport: "Informe",
                reportTerminalAuditReport: "Informe de auditoría de terminal",
                reportAuditReport: "Auditoría de terminal",
                reportTerminalReport: "Informe de terminal",
                lblMystery: "Misterio",
                lblPigBoom: "Pig Boom!",
                lblMannyBucks: "Manny Bucks",
                reportMysteryPrizeReport: "Informe del Premio Misterio",
                reportMannyBucksPrizeReport: "Informe del Premio Manny Bucks",
                reportBonusPrizeReport: "Informe de premios de bonificación",

                //Report Manager
                bySession: 'Por sesión',
                byPeriod: "Por periodo",
                byMachines: "Por máquinas",
                current: "Actual",
                perUser: "Por usuario",
                perCashier: "Por Cajero",
                lifeTime: "Tiempo de vida",
                summary: "Resumen",
                hist: "Historia",
                lblExport: "Exportar",
                lblSelectType: "Seleccione Tipo",
                lblSelectDevice: "Seleccione un dispositivo",
                lblFilter: "Filtro",
                lblSelectUser: "Seleccione a Usuario",
                lblSelectCashier: "Seleccione Cajero",
                lblSelectMachine: "Seleccionar máquina",
                lblCurrentSession: "Sesión actual",
                lblSelectDate: "Seleccione una fecha",
                lblClickToSelect: "Haga clic para seleccionar...",
                lblSelectSession: "Seleccione una sesión",
                messageErrorGetReport: "Failed to Get Report",
                checkFieldsReport: "Compruebe los campos de Generar informe.",
                messageExportReport: "A la espera de exportar el informe...",

                //License
                license_Title: "Licencia",

                //Debug
                debug_Title: "Depurar",
                pigBoom_card_Title: "PigBoom! Mandos",
                mystery_card_Title: "Mystery Mandos",
                multiProgressive_card_Title: "MultiProgressive Mandos",
                btnForcePigBoom: "Fuerza PigBoom!",
                btnForceMystery: "Fuerza Mystery",
                btnForceMultiProgressive: "Fuerza MultiProgressive Nivel",
                btnClearForceLevelList: "Borrar lista de nivel de fuerza",
                lblAllPigBoomGroups: "¡Todo Pig Boom! Grupos",
                lblAllMysteryGroups: "¡Todo Mystery Grupos",
                lblAllMannyBucksGroups: "¡Todo Manny Bucks Grupos",
                lblAllProgressivesGroups: "!Todos Progresistas Grupos",
                lblSelectMultiProgressive: "Seleccione MultiProgresivo",
                lblSelectPigBoom: "Seleccione PigBoom!",
                lblSelectMystery: "Seleccione Mystery",
                lblSelectMannyBucks: "Seleccione Manny Bucks",
                lblSelectProgressive: "Seleccione un progresivo",
                lblSelectLevel: "Seleccione un nivel",
                deckPigBoom_Title: "Pig Boom! Mandos",
                deckMystery_Title: "Mystery Mandos",
                deckMannyBucks_Title: "Manny Bucks Mandos",
                deckMultiProgressive_Title: "MultiProgressive Mandos",

                //noConnection
                messageNoConnectionFront: "Pérdida de comunicación con el servidor, probar una nueva conexión...",
                lblError: "Error",

                //Loading
                messageServerDidNotRespond: "El servidor no respondió al comando",
                messageServerAttempts: "en 3 intentos",
                messageServerRecive: "Respuesta recibida del comando",
                messageLimitTime: "en tiempo límite",
                messageServerFinishedCMD: "El servidor finalizó el comando",
                lblSucess: "Éxito",

                //GlobalProgressive
                globalData_tabName: "Datos Megapot",
                lblServerInfo: "Información del servidor",
                lblGlobalServerInfo: "IP de servidor Megapot",
                lblNotFound: "No encontrado",
                lblConectedServers: "Servidores conectados",
                resetGlobalProgTitle: "Establecer valor progresivo",
                globalProgressive_Title: "Megapot",
                btnShowEstablishment: "Mostrar operador y servidor",
                messageGetOperatorServer: "Operador y servidor",
                columnOperator: "Operador",
                columnServerName: "Nombre del servidor",
                columnShortName: "Nombre corto",
                columnaDirección: "Dirección",
                columnaCiudad: "Ciudad",
                columnDateUpdate: "Actualización de fecha",
                messageInfoDeleteOperator: "Al hacer clic en Eliminar, el sistema eliminará al operador de la base de datos y dejará de funcionar",

                //Request File
                lblRequestFile: "Solicitud de archivo",
                messageRestoreBackup: "Todos sus datos reales de bingo se perderán y serán reemplazados por los datos de copia de seguridad ¿Realmente desea continuar?",
                messageLoadConfig: "¿Realmente quieres cargar esta configuración?",
                btnDownload: "Descargar",
                btnDonwloadConfig: "Descargar Configuración!",
                btnDownloadFullConfig: "¡Descargue la configuración completa! Esto reinstalará la base de datos del establecimiento con la configuración más reciente.",
                lblPenDrive: "PenDrive",
                lblUploadFile: "Subir archivo",
                lblSelectItems: "Seleccionar artículos",
                messageErrorFileSize: "es mayor que 10 MB, elija un archivo más pequeño.", 

                //Add Cash
                btnCopy: "Copiar",
                messageCopy: "Acabas de copiar",
                messageCopyError: "Error al copiar textos",
                messageNewAddCasPassword: "Utilice este código para crear una nueva contraseña",
                messageResetAddCashPassword: "¿Realmente quieres restablecer la contraseña? Tenga en cuenta que deberá solicitar al administrador del sistema una nueva contraseña",
                btnResetPassoword: "Restablecer contraseña",
                messageValidateHashCode: "Validación del código hash",
                messageAddCash: "Esperando para agregar crédito",
                messageSaveHashCode: "Esperando para guardar el archivo de código hash",
                btnResetMach: "Restablecer Mach",
                btnCreateHashCode: "Crear código hash",
                messageRequestHashCode: "Solicitud de archivos de código hash",

                //Digi
                messageValidateDigi: "Espere hasta que el digi ip valide",
                btnSearch: "Buscar",

                //Logs
                logs_Title: "Trozas",
                screenLog_Option: "Opción de registro de pantalla",
                file_Option: "Registro de archivos",
                data_Option: "Registro de datos",
                machineLog_Title: "Registro de la máquina",
                panelLog_Title: "Registro de panel",
                globalLog_Title: "Registro Megapot",
                clientLog_Title: "Registro de clientes",
                messageShowLogs: "Mostrar registros",
                messageShowLogsEdt: "Esperando para editar mostrar registros",
                savePrefs_Option: "Guardar preferencias",
                lbl_DaysDeleteOldLogFiles : "Días Eliminar archivos de registro antiguos",

                //Dialog
                dialog_Title: "Sistema",    

                //Playlist
                playlistFooter_Title: "FooLista de reproducción de pie de páginater Playlist",
                playlist_Title: "Lista de reproducción de pie de página",
                lblSearchByPlaylist: "Buscar por lista de reproducción...",
                btnAddPlayList: "Agregar lista de reproducción",
                columnMessage: "Mensaje",                
                processCreatePlaylist: "Esperando para crear una lista de reproducción",
                processEditPlaylist: "Esperando para editar la lista de reproducción",
                processDeletePlaylistPart1: "¿Está seguro de que desea <b>eliminar</b> su lista de reproducción <b>(",
                processDeletePlaylistPart2: ")</b>? Esta acción no se puede deshacer.",
                messageDeletePlaylist: "Esperando para eliminar la lista de reproducción",
                processDeletePlaylistTitle: "Eliminar lista de reproducción",
                lblSelectPlaylist: "Selecciona Lista de reproducción",
                messageErrorDate: "Fecha ya elegida",
                messageErrorDeletePlaylist: "Lista de reproducción de error que se utiliza en los paneles",
                messagePlaylistExist1: "Playlist [",
                messagePlaylistExist2: "] ya existe",

                footer_Title: "Pie de página",                
                lblSearchByFooter: "Buscar por pie de página...",
                btnAddFooter: "Agregar pie de página",                
                processCreateFooter: "Esperando para crear pie de página...",
                processEditFooter: "Esperando para editar el pie de página...",
                processDeleteFooterPart1: "¿Está seguro de que desea <b>eliminar</b> su pie de página <b>(",
                processDeleteFooterPart2: ")</b>? Esta acción no se puede deshacer.",
                messageDeleteFooter: "Esperando para eliminar pie de página...",
                processDeleteFooterTitle: "Eliminación de pie de página",
                messageErrorFooter: "¡El mensaje ya existe!",
                messageErrorOption: "Seleccione al menos una opción",
                messageErrorDeleteFooter: "Mensaje de error que se utiliza en listas de reproducción",

                //Loading
                lblLoading: "Cargando",

                //terminalStatus
                All: "Todo",
                Logging: "Registro",
                WithCredit: "Con Crédito",
                Playing: "Jugando",
                InPayment: "En Pago",
                AdminScreen: "Pantalla de Administración",
                JackpotPayment: "Pago del Premio Mayor",
                DoorOpen: "Por Abierto",
                BillError: "Error de Factura",
                PrinterErr: "Error de iImpresora",
                MysteryElegible: "Mystery Elegible",
                Blocked: "Bloqueado",
                PigBoomElegible: "PigBoom! Elegible",
                Disconnected: "Desconectado",
                Connected: "Conexo",
                FinishedLogin: "Inicio de Sesión Finalizado",
                InLogin: "En el inicio de sesión",
                InFilter: "En filtro",

                //Cashier Dingo
                cashier_DingoTitle: "Cajero Dingo",
                btnManagerIn: "Gerente en",
                btnManagerOut: "Gerente Out",
                btnCashierIn: "Cajero en",
                btnCashierOut: "Cajero Out",
                btnPower: "Conectar",
                btnCloseVoucher: "Cerrar cupón",
                btnCloseVouchers: "Cerrar vales",
                btnVoucherInfo: "Información del cupón",
                btnVoucherViewer: "Visor de cupones",
                voucher_Title: "Opciones de cupones",
                register_Title: "Opciones de registro",                
                promotions_Title: "Opciones de promociones",
                manager_Title: "Opciones de Manager",
                btnReportManager: "Administrador de informes",
                btnUnlock: "Desbloquear",
                message_Promotions_Info_Coupon: "- El botón Gestión de cupones estará disponible junto a Opciones del cajero", 
                message_Promotions_Info_Validate: "- El botón Validar cupón estará disponible junto a Opciones de administrador",
                message_Error_Reprint: "Seleccione al menos un cupón para reimprimir",
                lblEnter_Coupon_Code: "Ingrese el código de cupón",

                //Operator
                operator_Title: "Operator",
                messageDeleteOperator: "¿Está seguro de que desea <b>eliminar</b> este operador?" , 
                lblConfiguration: "Configuración",

                //Client
                client_Title: "Clientes",
                servers_Title: "Servidores",
                activeClients: "Clientes Activos",
                inactiveClients: "Clientes Inactivos",
            }
        }
    }
}